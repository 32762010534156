import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useBranch } from "../contexts/BranchContext";
import eventOnedang from "../assets/1/event.webp";
import eventPyeongtaek from "../assets/2/event.webp";
import eventYeongdeungpo from "../assets/3/event.webp";
import eventWonju from "../assets/4/logo.webp";
import eventDaegu from "../assets/5/chilgok_event.webp";
import eventBugae from "../assets/6/bugae_event.webp";
import eventYongam from "../assets/7/price_cj.webp";
import eventAnyang from "../assets/8/anyang_event.webp";
import eventJeongwang from "../assets/9/event_jy.webp";
import eventYongsan from "../assets/10/event_ys.webp";
import eventBokdae from '../assets/12/bd_event.webp'
import eventApgujeong from '../assets/13/ap_event.webp'

import styled from "styled-components";

interface Props {
  title: string;
  isOpen: boolean;
  handleModal: (handle: boolean, desireClose: boolean) => void;
}

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 70vw;
  min-width: 600px;
  overflow: hidden;
  
  >img {
    width: 100%;
  }

  @media (max-width: 780px) {
    width: 100%;
    min-width: 0;
    >img {
      width: 100%;
      object-fit: cover;
    }
  }
`;


const CheckStyle = styled(Form.Check)`
  @media (max-width: 780px) {
    font-size: 1rem;
    line-height: 1.7rem;
  }
`;

function EventModal({ title, isOpen, handleModal }: Props) {
  const [branch, _] = useBranch();
  const [close, setClose] = useState(0);
  const photos = {
    onedang: eventOnedang,
    pyeongtaek: eventPyeongtaek,
    yeongdeungpo: eventYeongdeungpo,
    wonju: eventWonju,
    daegu: eventDaegu,
    bugae: eventBugae,
    yongam: eventYongam,
    anyang: eventAnyang,
    jeongwang: eventJeongwang,
    yongsan: eventYongsan,
    bokdae: eventBokdae,
    apgujeong: eventApgujeong
  };
  const closeModal = (open: boolean, close24: boolean) => {
    handleModal(open, close24);
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => closeModal(false, false)
      }
      dialogClassName="modal-auto"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="event-modal-title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImgWrapper>
          <img src={photos[branch as keyof typeof photos]} alt="event" />
        </ImgWrapper>
        <CheckStyle
          type="checkbox"
          value={close}
          onChange={() => closeModal(false, true)}
          label="오늘 하루 이벤트 팝업 보지 않기"
        />
      </Modal.Body>
    </Modal >
  );
}

export default EventModal;
