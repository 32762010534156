import information from "./information";

interface iRouter {
  label: string;
  path: string;
}

type iIntro = Record<iRouter["path"], iIntroDetail>

interface iIntroDetail {
  path: string;
  label: string;
  title: string;
  detail: string;
  tel: string;
  tel2?: string;
  scroll: string;
}


interface iLanding {
  intro: iIntro;
}


const Landing: iLanding = {
  intro: {
    "/": {
      label: "지점을 선택하세요",
      path: "",
      title: "",
      detail: "",
      tel: "",
      scroll: ""
    },
    "onedang": {
      path: "onedang",
      label: "인천 원당 1호점",
      title: "인천 원당점(비과밀억제권역)",
      detail: `온잇 공유오피스는 여러분의 비즈니스에 최적화된 업무 공간을 제공합니다.
인천검단신도시에 인접해있으며 상주/비상주 사무실을 합리적인 가격으로 이용할 수 있습니다.`,
      tel: "032)715-7555",
      scroll: "오픈 기념 이벤트 특가를 놓치기 싫다면?"
    },
    "pyeongtaek": {
      path: "pyeongtaek",
      label: "경기 평택 2호점",
      title: "경기 평택점(비과밀억제권역)",
      detail: `법인|개인 사업자 등록을 위한 주소지제공 서비스를 전국 최저가로 제공합니다.
맞춤책상과 전좌석 시디즈 의자를 갖춘 편리한 업무 및 스터디 공간으로 이용할 수 있습니다.`,
      tel: "031)373-6128",
      scroll: "오픈 기념 이벤트 특가를 놓치기 싫다면?"
    },
    "yeongdeungpo": {
      path: "yeongdeungpo",
      label: "서울 영등포구청 3호점",
      title: "영등포구청점(역세권 도보5분)",
      detail: `최신인테리어와 넓은 1인실,2인실을 가성비 있는 가격으로 상주 사무실을 이용 가능합니다.
비상주 사무실은 오픈행사 영등포 최저가로 할인 및 추가제공합니다(변동). 
      `,
      tel: "02)2633-7004",
      scroll: "오픈 기념 이벤트 특가를 놓치기 싫다면?"
    },
    "wonju": {
      path: "wonju",
      label: "강원 원주 4호점",
      title: "강원 원주점(비과밀억제권역)",
      detail: `강원 원주점은 비과밀억세권역으로 각종 세제혜택에 유리합니다.
상주/비상주 오피스를 전국에서 가장 저렴한 가격으로 이용할 수 있습니다.`,
      tel: "010-4239-8279",
      scroll: "오픈 특가 이벤트 진행 중!✨✨"
    },
    "daegu": {
      path: "daegu",
      label: "대구 칠곡 5호점",
      title: "대구 칠곡점(비과밀억제권역)",
      detail: `대구 칠곡점은 학정역 근처에 인접해있습니다.
상주(준비중)/비상주 오피스를 합리적인 가격으로 이용할 수 있습니다.`,
      tel: "0507-1478-5053",
      scroll: "오픈 특가 이벤트 진행 중!✨✨"
    },
    "bugae": {
      path: "bugae",
      label: "인천 부개 6호점",
      title: "인천 부개점",
      detail: `인천부개점은 타 공유오피스에 비해 가장 합리적인 금액으로 비상주 사무실을 제공합니다.
스터디카페도 최저가 수준으로 운영중이며, 개인석 이용시 오픈석을 무료로 제공합니다.`,
      tel: "032-278-0909",
      scroll: "가장 합리적인 가격으로 제공합니다✨✨"
    },
    "yongam": {
      path: "yongam",
      label: "청주 용암 7호점",
      title: "청주 용암점",
      detail: `합리적인 가격으로 업무에 필요한 모든 시설을 제공하는 "청주용암점"에서 성공적인 비즈니스의 시작을 함께하세요!
2인실부터 4인실까지, 외창이 있는 넓고 쾌적한 공간에서 보다 효율적이고 편안한 업무 환경을 경험하실 수 있습니다`,
      tel: "043-715-3177",
      scroll: "넓고 쾌적한 업무환경을 원하신다면?!✨✨"
    },
    "anyang": {
      path: "anyang",
      label: "경기 안양역 8호점",
      title: "경기 안양역점",
      detail: `안양역 도보 5분 거리의 안양 1번가 내에 위치하였습니다.
전좌석 고급 시디즈 의자로 구성되어 편안하고 넓은 사무실을 합리적인 가격으로 이용할 수 있습니다.
`,
      tel: "0507-1373-8321",
      scroll: "오픈 특가 0원 이벤트 진행 중!✨✨"
    },
    "jeongwang": {
      path: "jeongwang",
      label: "시흥 정왕점 9호점",
      title: "시흥 정왕점 (비과밀억제권역)",
      detail: `온잇 시흥 정왕점은 단독층의 110평 가량의 넓은 상주 사무실을 갖추고 있습니다. 
또한 오픈형 사무실과 스터디 카페도 있어 일과 학습이 모두 가능한 곳입니다.
전좌석 시디즈 의자로 편안한 업무와 학습을 지원해 드립니다. `,
      tel: "031-365-5865",
      scroll: "오픈 특가 이벤트 진행 중!✨✨"
    },
    // daegu - yongsan
    yongsan: {
      path: "yongsan",
      label: "대구 용산 10호점",
      title: "대구 용산점 (비과밀억제권역)",
      detail: `온잇 스터디카페&공유오피스(용산점)은 가장 합리적인 금액으로 비상주 사무실과 스터디카페를 제공합니다.
  법인설립, 사업자등록, 임대차계약, 실사대응, 우편물 관리 등 모든 방면에서 서포트가 가능합니다.`,
      tel: "053-721-7427",
      scroll: "오픈 특가 이벤트 진행 중!✨✨"
    },
    bokdae: {
      path: "bokdae",
      label: "청주 복대 12호점",
      title: "청주 복대점 (비과밀억제권역)",
      detail: `모든 호실은 창문이 있으며 1인실, 2인실, 3인실로 제공됩니다.
2평 내외의 넉넉한 1인실은 여유로운 분위기에서 업무에 집중하실 수 있습니다.`,
      tel: "043-233-4856",
      tel2: "010-5585-4856",
      scroll: "6개월/12개월 이상 완납시 할인 이벤트 진행중✨✨"
    },
    apgujeong: {
      path: "apgujeong",
      label: "서울 압구정 13호점",
      title: "서울 압구정점 (역세권 도보2분)",
      detail: `최신 인테리어로 넓은 방과 큰 창의 2인실, 4인실을 합리적인 가격으로 이용 가능합니다.
비상주 사무실은 오픈행사 압구정 최저가로 할인 및 추가제공 가능합니다(변동)`,
      tel: "02-6956-0500",
      tel2: "010-5957-0555",
      scroll: "오픈행사 압구정 추가 제공이벤트 진행중✨✨"
    }
  },
};

export default Landing;
export type { iIntroDetail, iLanding }
